<template>
  <mf-loading-dialog :loading="$apollo.loading || loading">
    <v-container class="pb-2 mt-n4" fluid>
      <v-layout>
        <p class="ml-1 page-subtitle">Cadastrar Upsell</p>
        <v-spacer />
      </v-layout>
      <v-card-actions class="px-0 mt-2 mb-1">
        <v-btn outlined width="300" color="error" @click="$router.push(`/retailers/${clientId}/edit/acoes`)">Cancelar</v-btn>
        <v-spacer />
        <v-btn width="300" color="primary" outlined @click="addProductModal = true"><v-icon class="mr-1">mdi-plus</v-icon> Inserir produto</v-btn>
        <v-btn width="300" color="primary" :disabled="!products.length" @click="confirm_sale = true"
          ><v-icon class="mr-2 mb-1">mdi-content-save-check</v-icon> Concluir Upsell</v-btn
        >
      </v-card-actions>
      <v-card class="pa-8">
        <v-card-actions>
          <v-spacer />
          <h2 class="mx-4 mb-2">Total: {{ total }}</h2>
        </v-card-actions>
        <v-data-table :footer-props="{ itemsPerPageOptions: [5, 10, 25, 50] }" :headers="headers" :items="products">
          <template v-slot:[`item.unit_value`]="{ item }">
            <v-flex>
              <v-layout justify-center align-center>
                {{ item.type === 'sms' ? parseCurrencySms(item.unit_value) : parseCurrencyDefault(item.unit_value) }}
              </v-layout>
            </v-flex>
          </template>

          <template v-slot:[`item.charge_type`]="{ item }">
            <v-flex>
              <v-layout justify-center align-center>
                {{ item.type === 'sms' || item.type === 'mercalider' ? (item.charge_type === 'recurring' ? 'Recorrente' : 'Única') : '-' }}
              </v-layout>
            </v-flex>
          </template>

          <template v-slot:[`item.is_accumulative`]="{ item }">
            <v-flex>
              <v-layout justify-center align-center>
                {{ item.type === 'sms' ? (item.is_accumulative ? 'Sim' : 'Não') : '-' }}
              </v-layout>
            </v-flex>
          </template>

          <template v-slot:[`item.has_order`]="{ item }">
            <v-flex>
              <v-layout justify-center align-center>
                {{ item.type === 'mercashop' ? (item.has_order ? 'Sim' : 'Não') : '-' }}
              </v-layout>
            </v-flex>
          </template>

          <template v-slot:[`item.implantation_value`]="{ item }">
            <v-flex>
              <v-layout justify-center align-center>
                {{ getProductFromType(item.type).hasSetupCost ? parseCurrencyDefault(item.implantation_value) : '-' }}
              </v-layout>
            </v-flex>
          </template>
          <template v-slot:[`item.plan`]="{ item }">
            <v-flex>
              <v-layout justify-center align-center>
                {{ item.type === 'mercalider' ? item.plan : '-' }}
              </v-layout>
            </v-flex>
          </template>
          <template v-slot:[`item.total_value`]="{ item }">
            <v-flex>
              <v-layout justify-center align-center>
                {{ parseCurrencyDefault(item.total_value) }}
              </v-layout>
            </v-flex>
          </template>

          <template v-slot:[`item.options`]="{ item }">
            <v-flex>
              <v-layout justify-center align-center>
                <v-btn text color="error" @click="popProduct(item)">Remover</v-btn>
              </v-layout>
            </v-flex>
          </template>
        </v-data-table>
      </v-card>
    </v-container>

    <add-product-dialog
      :cardSubtitle="'Selecione um dos produtos ativos: '"
      :dialog="addProductModal"
      :client-units="clientUnits"
      show-units-input
      :products="products"
      :availableProducts="availableProductsList"
      @close="close"
    />
    <dialog-confirm-sale
      v-model="confirm_sale"
      is-sale
      :validate-contract-link="true"
      :disable-button="disable_button"
      :has-billing-date="true"
      @confirm-action="saveSale"
    />
  </mf-loading-dialog>
</template>

<script>
import { MUTATION_INSERT_SALE, QUERY_GET_AVAILABLE_PRODUCTS } from '@/modules/retailers/graphql'
import { ProductsById } from '@/constants'
import { parseCurrency, productsInformation } from '@/mixin'
export default {
  components: {
    AddProductDialog: () => import('../../../../../components/atomic-components/organism/AddProductDialog.vue'),
    DialogConfirmSale: () => import('../../../../../components/atomic-components/molecules/DialogConfirmSale')
  },
  mixins: [parseCurrency, productsInformation],
  props: {
    clientId: {
      type: String,
      default: () => ''
    },
    salesType: {
      type: String,
      default: () => ''
    },
    clientUnits: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    products: [],
    addProductModal: false,
    loading: false,
    maxRecords: 0,
    availableProductsList: [],
    confirm_sale: false,
    disable_button: false
  }),
  computed: {
    isCreate() {
      return this.$route.meta.title === 'Nova venda'
    },
    saleTypeMapped() {
      const mapSales = {
        new_sale: 'Nova venda',
        upsell: 'Upsell',
        dowsell: 'Downsell',
        temporary_churn: 'Abono temporário'
      }

      return mapSales[this.salesType]
    },
    headers() {
      return [
        {
          text: 'Nome',
          value: 'name',
          align: 'center'
        },
        {
          text: 'Valor unitário',
          value: 'unit_value',
          align: 'center'
        },
        {
          text: 'Quantidade',
          value: 'quantity',
          align: 'center'
        },
        {
          text: 'Tipo de cobrança',
          value: 'charge_type',
          align: 'center'
        },
        {
          text: 'Acumulativo',
          value: 'is_accumulative',
          align: 'center'
        },
        {
          text: 'Valor da implantação',
          value: 'implantation_value',
          align: 'center'
        },
        {
          text: 'Plano',
          value: 'plan',
          align: 'center'
        },
        {
          text: 'Valor total',
          value: 'total_value',
          align: 'center'
        },
        {
          text: '',
          value: 'options',
          align: 'center'
        }
      ]
    },
    total() {
      let totalValue = 0
      this.products.map(item => (totalValue += item?.total_value))
      return this.parseCurrencyDefault(totalValue)
    }
  },
  apollo: {
    getAvailableProducts: {
      query: QUERY_GET_AVAILABLE_PRODUCTS,
      fetchPolicy: 'network-only',
      variables() {
        return Object.assign(
          {},
          { client_id: this.clientId },
          { filters: {} },
          {
            pagination: {
              page: this?.options?.page || 1,
              pageSize: this?.options?.itemsPerPage || 10,
              search: this?.search || null,
              sort: this?.options?.sortBy || ['_id'],
              sortDirection: 'DESC'
            }
          }
        )
      },
      update({ availableProducts: { count, data } }) {
        this.maxRecords = count
        this.availableProductsList = data
          .filter(product => product.active)
          .map(product => {
            return {
              _id: product._id,
              name: ProductsById[product.product_type]?.name || product.product_type,
              value: product.product_type
            }
          })
      },
      context() {
        return {
          headers: {
            authorization: localStorage.getItem('session_id')
          },
          uri: this.$microservicesUrls['crm']
        }
      }
    }
  },
  methods: {
    close() {
      this.addProductModal = false
    },
    popProduct(product) {
      const index = this.products.indexOf(product)
      this.products.splice(index, 1)
    },
    async saveSale({ date, contract_link, ambev_incentive, is_ambev_participation, observation, billing_date, origin, sub_origin }) {
      this.disable_button = true
      try {
        await this.$apollo.mutate({
          mutation: MUTATION_INSERT_SALE,
          variables: {
            client_id: this.clientId,
            sale: {
              type: this.salesType,
              items: this.products,
              created_at: date,
              contract_link,
              ambev_incentive,
              is_ambev_participation,
              observation,
              billing_date,
              origin,
              sub_origin
            }
          },
          context: {
            uri: this.$microservicesUrls['crm']
          }
        })

        this.$alert({
          alert_message: 'Venda inserida com sucesso',
          alert_title: 'Sucesso!',
          alert_color: 'success',
          alert_icon: 'mdi-check-circle'
        })
        this.$router.push(`/retailers/${this.clientId}/edit/acoes`)
        this.disable_button = false
      } catch (err) {
        console.log(err?.message)
        this.disable_button = false
        this.$alert({
          alert_message: 'Falha ao inserir venda',
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .v-select.v-select--is-menu-active .v-input__icon--append .v-icon {
  transform: none !important;
}
</style>
